<template>
    <modal ref="modalCalificacionDriver" titulo="Calificación del Driver" no-aceptar no-cancelar>
        <div class="row mx-0 justify-center">
            <div class="col-10 border-bottom pb-3 d-flex">
                <p class="col-auto text-general f-400 px-2 f-15">
                    General
                </p>
                <div class="col">
                    <p class="text-right">
                        <span class="text-general f-600 f-15">
                            4.81 <i class="icon-star text-warning f-20" />
                        </span>
                    </p>
                    <p class="text-general f-300 text-right mt-2">
                        46 Calificaciones
                    </p>
                </div>
            </div>
            <div class="col-10 mt-3">
                <div v-for="data in 6" :key="data" class="row mx-0 align-items-center justify-content-between mb-3">
                    <p class="col-auto px-2 f-15 f-300 text-general">
                        Rapidez
                    </p>
                    <div class="col-auto f-15 text-general">
                        3.71 <i class="icon-star text-warning f-20" />
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    methods: {
        toggle(){
            this.$refs.modalCalificacionDriver.toggle();
        }
    }
}
</script>